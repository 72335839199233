<template>
    <div>
        <div class="main-title">消息队列</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">*****</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">








            </div>


            <div class="table-content">

                <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%;background: #fff;">
                    <el-table-column label="ID" property="id" width="80">
                    </el-table-column>
                    <el-table-column label="标题" property="title" >
                    </el-table-column>


                    <el-table-column property="created_at" label="创建时间" >
                    </el-table-column>
                    <el-table-column property="index" label="状态" >
                        <template slot-scope="scope">
                            <span>{{ scope.row.status == 0?'未读':'已读' }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column property="index" label="类型" >
                        <template slot-scope="scope">
                            <span>{{ scope.row.type == 1?'文件下载':'文本信息' }}</span>
                        </template>
                    </el-table-column>


                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span><el-link slot="reference" type="primary"
                                    @click="details(scope.row.id)">详情</el-link></span>

                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog :title="messageData.title" append-to-body :visible.sync="dialogVisible" width="30%"
				>
				<div style="padding:10px;" v-loading="dialogVisibleLoading">
					<span>{{ messageData.message}}</span>
					<div style="margin-top:40px;">
						<el-button @click="dialogVisible = false">关闭</el-button>
						<el-button type="primary" v-if="messageData.type == 1">
							<a :href="messageData.url">下载</a></el-button>
					</div>
				</div>

			</el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "list",
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            loading: true,
            dialogVisible:false,
            dialogVisibleLoading:false,
            messageData:{},
            tableData: [],
        }
    },
    methods: {
        ...mapActions('message', ['userMessageStationList', 'userMessageStationDetail']),
        async getList() {
            this.loading = true
            let form = {
                page: this.page,
                pageSize: this.pageSize,
            }
            const { data } = await this.userMessageStationList(form)
            this.tableData = data.list
            this.total = data.total
            this.loading = false
        },
        setPageSize(pageSize){
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page){
            this.page = page
            this.getList()
        },
       async details(id){
            this.dialogVisible = true
			this.dialogVisibleLoading = true
			const { data } = await this.userMessageStationDetail(id)
			this.messageData = data
			this.dialogVisibleLoading = false
        },
    },
    mounted() {
        this.getList()
    }
}
</script>

<style scoped>

</style>
